<template>
  <div>
    <el-upload
      :action="action"
      list-type="picture-card"
      :before-upload="beforeUpload"
      :limit="limit"
      :file-list="fileList"
      :on-preview="handlePreview"
      :on-remove="onRemove"
      :class="{ hide: hideUpload }"
    >
      <i class="el-icon-plus"></i>
      <p slot="tip" class="el-upload__tip">
        只能上传视频格式的文件，且不超过20MB
      </p>
    </el-upload>

    <el-dialog :visible.sync="dialogVisible">
      <video width="100%" :src="dialogFileUrl" controls />
    </el-dialog>
  </div>
</template>

<script>
import { putFile } from "@/api/oss";
export default {
  props: {
    limit: {
      type: Number,
      default: 1,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      action: "/api/blade-resource/oss/endpoint/put-file",
      fileList: [],
      dialogVisible: false,
      dialogFileUrl: "",
    };
  },
  computed: {
    hideUpload() {
      if (this.value.length >= this.limit) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    value(val) {
      if (val.length) {
        if (!val[0].realurl) {
          this.fileList = val.map((item) => {
            return {
              url: item.url + "?x-oss-process=video/snapshot,t_10000,m_fast",
              realurl: item.url,
            };
          });
        }
      }

      // this.fileList = [];
    },
    fileList(val) {
      this.$emit("change", val);
    },
  },
  methods: {
    beforeUpload(file) {
      console.log("file===",file)
      const isLt10M = file.size / 1024 / 1024 < 20;
      if (!/video/.test(file.type)) {
        this.$message.error("只能上传视频格式，且不超过20M");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传视频大小不能超过20MB哦!");
        return false;
      }
      let uploadMessage = this.$message({
        message: "文件上传中",
        iconClass: "el-icon-loading",
        duration: 0,
      });
      putFile(file)
        .then((res) => {
          this.fileList.push({
            url: res.data.link + "?x-oss-process=video/snapshot,t_10000,m_fast",
            realurl: res.data.link,
          });
          uploadMessage.close();
        })
        .catch((e) => {
          uploadMessage.close();
          console.log(e);
          if (e.message.includes("timeout")) {
            this.$message.error("请求超时，请确保网络通畅");
          } else {
            this.$message.error(e);
          }
        });

      return false;
    },
    handlePreview(file) {
      this.dialogFileUrl = file.realurl;
      this.dialogVisible = true;
    },
    onRemove(file, fileList) {
      this.fileList = [...fileList];
      // this.fileList = JSON.parse(JSON.stringify(fileList));
    },
  },
};
</script>

<style lang="scss" scoped>
/* .exceed .el-upload--picture-card {
  display: none;
}
.upload__tip {
  font-size: 12px;
  color: #b3b3b3;
} */
::v-deep{
  .hide .el-upload--picture-card{
    display: none;
  }
}
</style>
