<template>
  <div class="ask">
    <div class="location">
      <i class="el-icon-place"></i>
      <el-breadcrumb
        class="el__breadcrumb"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item>您当前的位置</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>我要问政</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <el-form
        class="el__form"
        ref="form"
        size="small"
        label-suffix=":"
        :model="form"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item prop="deptCategory" label="部门类型">
          <el-select
            v-model="form.deptCategory"
            placeholder="请选择部门类型"
            @change="deptCategoryChange"
            clearable
          >
            <el-option
              v-for="(item, index) in deptCategorys"
              :key="index"
              :label="item.key"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="deptId" label="部门名称">
          <el-select
            v-model="form.deptId"
            placeholder="请选择部门"
            filterable
            clearable
          >
            <el-option
              v-for="item in deptList"
              :key="item.id"
              :label="item.deptName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="title" label="标题">
          <el-autocomplete
            v-model="form.title"
            clearable
            placeholder="请输入标题，不超过50字"
            value-key="question"
            :trigger-on-focus="false"
            :fetch-suggestions="querySearchAsync"
            @select="handleSelect"
            popper-class="popper"
            :maxlength="50"
            show-word-limit
          >
            <div class="pop" slot-scope="{ item }">
              <div class="name" v-html="item.title"></div>
              <!-- <span class="des">{{ item.deptName }}</span> -->
            </div>
          </el-autocomplete>
        </el-form-item>
        <el-form-item prop="content" label="问题描述">
          <tinymce-editor
            @tinymceinput="innerHtml"
            ref="editor"
            :tinyValue="form.content"
          ></tinymce-editor>
        </el-form-item>
        <el-form-item prop="isClue" label="允许记者采访">
          <el-switch v-model="form.isClue" active-color="#13ce66"> </el-switch>
        </el-form-item>

        <el-form-item prop="imgUrl" label="上传图片">
          <el-upload
            list-type="picture-card"
            accept="png"
            :limit="9"
            :file-list="form.imgUrl"
            action
            :http-request="uploadFile"
            :before-upload="beforeUpload"
            :on-remove="handleRemove"
            :class="{ hide: hideUpload }"
          >
            <i class="el-icon-plus"></i>
            <p slot="tip" class="el-upload__tip">
              只能上传图片格式的文件，且不超过5M，最多可上传9张
            </p>
          </el-upload>
          <!-- <upload-file
            accept="image/*"
            :limit="3"
            @change="changeImgs"
            :value="form.imgUrl"
          ></upload-file> -->
        </el-form-item>
        <el-form-item prop="videoUrl" label="上传视频">
          <upload-video
            accept="video/*"
            :limit="3"
            @change="changeVideos"
            :value="form.videoUrl"
          ></upload-video>
        </el-form-item>
        <el-form-item>
          <el-button
            :disabled="subDisabled"
            class="el__btn"
            @click="submit('form')"
            >{{ id ? "修改" : "提交" }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import TinymceEditor from "@/components/TinymceEditor";
// import UploadFile from "@/components/UploadFile";
import UploadVideo from "@/components/UploadVideo";
import { getDeptList } from "@/api/dept";
import { recommend, IKList } from "@/api/textcorpus";
import { putFile } from "@/api/oss";
import { addAsk, getAskDetail, updateAsk } from "@/api/wzasksevent";
import auth from "@/mixins/auth";
export default {
  name: "ask",
  mixins: [auth()],
  components: {
    TinymceEditor,
    // UploadFile,
    UploadVideo,
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    hideUpload() {
      if (this.form.imgUrl.length >= 9) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      deptCategorys: [
        {
          key: "部门",
          value: 2,
        },
        {
          key: "区县",
          value: 1,
        },
        // {
        //   key: "区县（部门）",
        //   value: 3,
        // },
        // {
        //   key: "社区",
        //   value: 5,
        // },
      ],
      deptList: [],
      form: {
        deptCategory: "",
        deptId: "",
        title: "",
        content: "",
        imgUrl: [],
        videoUrl: [],
        isClue: false,
      },
      rules: {
        deptCategory: [
          { required: true, message: "请选择部门类型", trigger: "change" },
        ],
        deptId: [{ required: true, message: "请选择部门", trigger: "change" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
      subDisabled: false,
      keywords: [
        "script",
        "svg",
        "alert",
        "confirm",
        "prompt",
        "onload",
        "onmouseover",
        "onfocus",
        "onerror",
        "xss",
      ],
    };
  },
  mounted() {
    this.id && this.getAskDetail(this.id);
  },
  methods: {
    uploadFile(row) {
      putFile(row.file).then((res) => {
        this.form.imgUrl.push({
          url: res.data.link,
        });
      });
    },
    // handleChange(file, fileList) {
    //   console.log("handleChange", file, fileList);
    //   this.form.imgUrl = fileList;
    // },
    // 图片上传验证
    beforeUpload(file) {
      const isJPG = /image/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 5;
      // console.log(file);
      if (!isJPG) {
        this.$message.error("只能图片格式的文件!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过5MB!");
      }
      return isJPG && isLt2M;
    },
    handleRemove(file, fileList) {
      console.log("handleRemove", file, fileList);
      this.form.imgUrl = fileList;
    },

    async getAskDetail(id) {
      const res = await getAskDetail(id);
      // console.log(res)
      const { deptCategory, deptId, title, content, isClue, imgUrl, videoUrl } =
        res.data;
      const deptres = await getDeptList(deptCategory);
      this.deptList = deptres.data;
      const data = { deptCategory, deptId, title, content };
      if (imgUrl !== "") {
        data.imgUrl = imgUrl.split(",").map((img) => ({
          name: "",
          url: img,
        }));
      } else {
        data.imgUrl = [];
      }
      if (videoUrl !== "") {
        data.videoUrl = videoUrl.split(",").map((video) => ({
          name: "",
          url: video,
        }));
      } else {
        data.videoUrl = [];
      }
      data.isClue = isClue == 1 ? true : false;
      this.form = data;
    },
    async deptCategoryChange(val) {
      if (val) {
        const res = await getDeptList(val);
        // console.log(res)
        this.deptList = res.data;
      } else {
        this.deptList = [];
      }
      this.form.deptId = "";
    },
    handleSelect(val) {
      // console.log(val);
      this.$router.push("/web/detail?id=" + val.id);
    },
    async querySearchAsync(queryString, callback) {
      console.log(queryString, callback);
      if (queryString.length < 2) {
        callback([]);
        return;
      } else {
        const results = await IKList(queryString);
        callback(results.data);
      }
    },
    changeVideos(val) {
      this.form.videoUrl = val;
    },
    changeImgs(val) {
      this.form.imgUrl = val;
    },
    innerHtml(val) {
      this.form.content = val;
    },
    submit(formName) {
      console.log(this[formName]);
      const { deptId, title, content, videoUrl, isClue } = this[formName];
      // const localImg = imgUrl.filter((item) => item.raw);
      // const successImg = imgUrl.filter((item) => !item.raw);
      // const localVideo = videoUrl.filter(item => item.raw)
      // const successVideo = videoUrl.filter(item => !item.raw)
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // const imgUrls = localImg.map((i) => i.raw);
          // const videoUrls = localVideo.map(i=>i.raw)
          // const imgRes = await mutiUpload(imgUrls);
          // const videoRes = await mutiUpload(videoUrls);
          // const mergeImgStr = successImg
          //   .map((i) => i.url)
          //   .concat(imgRes.map((i) => i.data.link))
          //   .join(",");
          // const mergeVideoStr = successVideo.map(i=>i.url).concat(videoRes.map(i=>i.data.link)).join(',')
          // console.log(imgRes,videoRes);
          let keywords = this.keywords;
          const keywordIndex = keywords.findIndex((keyword) =>
            content.includes(keyword)
          );

          if (keywordIndex != -1) {
            this.$message.error(
              `内容中包含非法关键词${keywords[keywordIndex]}`
            );
            return;
          }

          let res = "";
          const params = {
            title,
            content,
            deptId,
            isClue: isClue ? 1 : 0,
            imgUrl: this.form.imgUrl
              .map((item) => {
                return item.url;
              })
              .join(","),
            videoUrl: videoUrl.map((i) => i.realurl).join(","),
          };

          this.subDisabled = true;
          if (this.id) {
            res = await updateAsk(this.id, params);
          } else {
            res = await addAsk(params);
          }
          // console.log(res)
          res && this.$message.success(res.msg);
          setTimeout(() => {
            this.$router.push("/web/online");
          }, 500);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ask {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .location {
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    align-items: center;
    .el__breadcrumb {
      margin-left: 8px;
    }
  }
  .content {
    padding: 80px 0 180px;
    .el__form {
      margin-top: 24px;
      .el-input,
      .el-select,
      .el-autocomplete {
        width: 600px;
      }
      .el__btn {
        width: 156px;
        background: #5cbd31;
        border: 0;
        color: #ffffff;
      }
    }
  }
}
.popper {
  .pop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      color: #333333;
    }
    .des {
      color: #97a6b7;
    }
  }
}
::v-deep {
  .hide .el-upload--picture-card {
    display: none;
  }
}
</style>
