import { mapState } from "vuex";
export default function () {
  return {
    computed: {
      ...mapState({
        userInfo: (state) => state.userInfo,
      }),
    },
    data() {
      return {}
    },
    created(){
      this.isLogin()
    },
    methods: {
      isLogin(){
        if(!this.userInfo||!(this.userInfo.nick_name || this.userInfo.account)){
          this.$router.push('/web/login')
        }
      }
    }
  }
}