<template>
  <div class="editor">
    <editor id="tinymce" v-model="tinymceHtml" :init="init"></editor>
  </div>
</template>

<script>
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import 'tinymce/themes/silver/theme'
import "tinymce/icons/default";
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/textcolor'
export default {
  components: { Editor },
  props: {
    tinyValue: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plugins: {
      type: [String, Array],
      default: "link lists image code table colorpicker textcolor wordcount contextmenu",
    },
    toolbar: {
      type: [String, Array],
      default:
        "bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat",
    },
  },
  watch: {
    tinyValue(newValue) {
      this.tinymceHtml = newValue;
    },
    tinymceHtml(newValue) {
      this.$emit("tinymceinput", newValue);
    },
  },
  data() {
    return {
      tinymceHtml: this.tinyValue,
      init: {
        language_url: "/tinymce/langs/zh_CN.js",
        language: "zh_CN",
        skin_url: "/tinymce/skins/ui/oxide",
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px',
        height: 300,
        width:600,
        plugins: this.plugins,
        toolbar: this.toolbar,
        branding: false,
        // menubar: false,
        // images_upload_handler: (blobInfo, success, failure) => {},
      },
    };
  },
  mounted() {
    tinymce.init({});
  },
};
</script>

<style>
</style>